<template>
  <div>
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <button
        type="button"
        class="btn mulai btn-secondary font-bold"
        @click="handleNew"
      >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT BAB BARU
      </button>
    </div>
    <br />
    <br />

    <label>Filter Jenjang</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="level_id"
        :options="levelOptionList"
        @change="changeLevel"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4">Filter Kurikulum</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="curriculum_id"
        :options="curriculumOptionList"
        @change="changeCurriculum"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4" v-if="majorOptionList.length > 1">Filter Jurusan</label><br />
    <b-input-group size="sm" v-if="majorOptionList.length > 1">
      <b-form-select
        v-model="major_id"
        :options="majorOptionList"
        @change="changeMajor"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4">Filter Kelas</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="class_id"
        :options="classOptionList"
        @change="changeClass"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <label class="mt-4">Filter Mapel</label><br />
    <b-input-group size="sm">
      <b-form-select
        v-model="subject_id"
        :options="subjectOptionList"
        @change="changeSubject"
        class="my-select"
      ></b-form-select>
    </b-input-group>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-danger"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="minus-circle" class="my-icon" />&nbsp;Delete
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-primary"
          @click="handleGo(row.item)"
        >
          <fa-icon icon="arrow-right" class="my-icon" />&nbsp;Go
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      v-model="addModal"
      id="addModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <modal-cross @close="addModal = false"></modal-cross>
      <div class="p-4">
        <h3 class="txt-center color2">{{editData ? "Edit Bab" : "Buat Bab Baru"}}</h3>
        <br />
        <label>Pilih Jenjang</label>
        <br />
        <b-form-select
          v-model="inputData.level_id"
          :options="levelOption"
          required
          :disabled="editData"
          @change="changeInputLevel(null)"
        ></b-form-select>
        <label class="mt-4">Pilih Kurikulum</label>
        <br />
        <b-form-select
          v-model="inputData.curriculum_id"
          :options="curriculumOption"
          required
          :disabled="editData"
        ></b-form-select>
        <label class="mt-4" v-if="majorOption.length > 0">Pilih Jurusan</label>
        <b-form-select
          v-model="inputData.major_id"
          :options="majorOption"
          :disabled="editData"
          class="mt-2"
          @change="changeInputMajor(null)"
          v-if="majorOption.length > 0"
        ></b-form-select>
        <label class="mt-4">Pilih Kelas</label>
        <b-form-select
          v-model="inputData.class_id"
          :options="classOption"
          :disabled="editData"
          class="mt-2"
          @change="changeInputClass(null)"
        ></b-form-select>
        <label class="mt-4">Pilih Mapel</label>
        <b-form-select
          v-model="inputData.subject_id"
          :options="subjectOption"
          :disabled="editData"
          class="mt-2"
        ></b-form-select>
        <br />
        <br />
        <br />
        <label>Nama Bab</label>
        <br />
        <b-form-input
          placeholder="Nama .. "
          v-model="inputData.name"
        ></b-form-input>
        <br />
        <label>Deskripsi</label>
        <b-form-input
          placeholder="Deskripsi Singkat .. "
          v-model="inputData.description"
        ></b-form-input>
        <br />
        <div class="upload-image">
          <div class="upload-image__item">
            <div class="upload-image__top">
              <div class="image-container">
                <img
                  class="image-container-img"
                  :src="inputData.thumbnail_src"
                  alt="img"
                  style="max-height:108px"
                />
                <div
                  class="img-remove"
                  v-show="inputData.thumbnail_src != '/img/thumbnail.png'"
                  @click="removeImage()"
                >
                  X Remove
                </div>
              </div>
            </div>
            <div class="upload-image__bottom">
              <input
                type="file"
                :name="inputData.thumbnail"
                id="inputIcon"
                class="hidden-input"
                @change="changeThumbnail()"
              />
              <label for="inputIcon" class="file-upload">
                <fa-icon icon="upload" class="my-icon" />
                Upload Thumbnail
              </label>
            </div>
          </div>
        </div>
        <div class="justify-end">
          <my-button
            type="blue"
            disabled
            v-show="showLoading"
          >
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </my-button>
          <my-button
            type="blue"
            v-show="!showLoading"
            @click="handleSave"
          >
            SIMPAN
          </my-button>
        </div>
        <br />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      editData: false,
      level_id: null,
      levelOptionList: [],
      major_id: null,
      majorOptionList: [],
      class_id: null,
      classOptionList: [],
      subject_id: null,
      subjectOptionList: [],
      curriculum_id: null,
      curriculumOptionList: [],
      isBusy: false,
      items: [],
      fields: [
        {
          key: "no",
          label: "No"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "subject",
          label: "Mapel",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "class_major",
          label: "Kelas"
        },
        {
          key: "curriculum",
          label: "Kurikulum",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "level",
          label: "Jenjang",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "actions",
          label: "Action"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      addModal: false,
      inputData: {
        name: "",
        description: "",
        level_id: null,
        major_id: null,
        class_id: null,
        subject_id: null,
        curriculum_id: null,
        id: null,
        thumbnail_src: "/img/thumbnail.png",
        thumbnail: null,
        removeOld: 0
      },
      showLoading: false,
      levelOption: [],
      majorOption: [],
      classOption: [],
      subjectOption: [],
      curriculumOption: []
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.levelList();
    this.majorList();
    this.classList();
    this.subjectList();
    this.curriculumList();
    if(this.$route.query.level) {
      this.level_id = this.$route.query.level;
    } 
    if(this.$route.query.major) {
      this.major_id = this.$route.query.major;
    } 
    if(this.$route.query.class) {
      this.class_id = this.$route.query.class;
    } 
    if(this.$route.query.subject) {
      this.subject_id = this.$route.query.subject;
    } 
    if(this.$route.query.curriculum) {
      this.curriculum_id = this.$route.query.curriculum;
    } 
    this.chapterList();
  },

  watch: {
    $route: function(newVal) {
      this.level_id = this.$route.query.level ? this.$route.query.level : null;
      this.major_id = this.$route.query.major ? this.$route.query.major : null;
      this.class_id = this.$route.query.class ? this.$route.query.class : null;
      this.majorList();
      this.classList();
      this.subjectList();
      this.chapterList();
    }
  },

  methods: {
    ...mapActions({
      getLevelList: types.GET_LEVEL_LIST,
      getCurriculumList: types.GET_CURRICULUM_LIST,
      getChapterList: types.GET_CHAPTER_LIST,
      getMajorList: types.GET_MAJOR_LIST,
      getClassList: types.GET_CLASS_LIST,
      getSubjectList: types.GET_SUBJECT_LIST,
      setNewChapter: types.SET_NEW_CHAPTER,
      getChapterDetail: types.GET_CHAPTER_DETAIL,
      updateChapter: types.UPDATE_CHAPTER,
      deleteChapter: types.DELETE_CHAPTER
    }),
    handleNew() {
      this.addModal = true;
      this.editData=false;
      this.inputData = {
        name: "",
        description: "",
        level_id: null,
        major_id: null,
        class_id: null,
        subject_id: null,
        curriculum_id: null,
        id: null,
        thumbnail_src: "/img/thumbnail.png",
        thumbnail: null,
        removeOld: 0
      }
    },
    removeImage() {
      this.inputData.thumbnail_src = "/img/thumbnail.png";
      this.inputData.thumbnail = null;
      this.inputData.removeOld = 1;
    },
    changeThumbnail() {
      var fileData = event.target.files[0];
      this.inputData.thumbnail = fileData;
      this.inputData.thumbnail_src = URL.createObjectURL(fileData);
      this.inputData.removeOld = 0;
    },
    changeInputLevel(major_id = null) {
      if(this.inputData.level_id) {
        this.inputData.major_id = major_id;
        this.changeInputMajor(null);
        this.getMajorList({params: {level: this.inputData.level_id}})
          .then(response => {
            var res = response;
            this.majorOption = [];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.majorOption.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } 
    },
    changeInputMajor(class_id = null) {
      this.inputData.class_id = class_id;
      this.getClassList({params: {level: this.inputData.level_id, major: this.inputData.major_id}})
        .then(response => {
          var res = response;
          this.classOption = [];
          for (var i = 0; i < res.length; i++) {
            var dataclass = {
              value: res[i].id,
              text: res[i].name
            };
            this.classOption.push(dataclass);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    changeInputClass(subject_id = null) {
      this.inputData.subject_id = subject_id;
      this.getSubjectList({params: {level: this.inputData.level_id, major: this.inputData.major_id, class: this.inputData.class_id}})
        .then(response => {
          var res = response;
          this.subjectOption = [];
          for (var i = 0; i < res.length; i++) {
            var datasubject = {
              value: res[i].id,
              text: res[i].name
            };
            this.subjectOption.push(datasubject);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    changeSubject() {
      var route = "/master-data/chapter?level="+this.level_id;
      if(this.subject_id) {
        route += "&subject="+this.subject_id;
      }
      if(this.class_id) {
        route += "&class="+this.class_id;
      }
      if(this.major_id) {
        route += "&major="+this.major_id;
      }
      if(this.curriculum_id) {
        route += "&curriculum="+this.curriculum_id;
      }
      this.$router.push(route);
    },
    changeClass() {
      var route = "/master-data/chapter?level="+this.level_id;
      if(this.class_id) {
        route += "&class="+this.class_id;
      }
      if(this.major_id) {
        route += "&major="+this.major_id;
      }
      if(this.curriculum_id) {
        route += "&curriculum="+this.curriculum_id;
      }
      this.$router.push(route);
    },
    changeMajor() {
      var route = "/master-data/chapter?level="+this.level_id;
      if(this.major_id) {
        route += "&major="+this.major_id;
      }
      if(this.curriculum_id) {
        route += "&curriculum="+this.curriculum_id;
      }
      this.$router.push(route);
    },
    changeLevel() {
      if(this.level_id && this.curriculum_id) {
        this.$router.push("/master-data/chapter?level="+this.level_id+"&curriculum="+this.curriculum_id);
      } else if(this.level_id) {
        this.$router.push("/master-data/chapter?level="+this.level_id);
      } else {
        this.$router.push("/master-data/chapter");
      }
    },
    changeCurriculum() {
      var route = this.level_id ? "/master-data/chapter?level="+this.level_id : "/master-data/chapter?level=";
      if(this.subject_id) {
        route += "&subject="+this.subject_id;
      }
      if(this.class_id) {
        route += "&class="+this.class_id;
      }
      if(this.major_id) {
        route += "&major="+this.major_id;
      }
      if(this.curriculum_id) {
        route += "&curriculum="+this.curriculum_id;
      }
      this.$router.push(route);
    },
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [];
          this.levelOptionList = [{value: null, text: "All"}];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
            this.levelOptionList.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    curriculumList() {
      this.getCurriculumList()
        .then(response => {
          var res = response;
          this.curriculumOption = [];
          this.curriculumOptionList = [{value: null, text: "All"}];
          for (var i = 0; i < res.length; i++) {
            var datacurriculum = {
              value: res[i].id,
              text: res[i].name
            };
            this.curriculumOption.push(datacurriculum);
            this.curriculumOptionList.push(datacurriculum);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    majorList() {
      var level_id = this.$route.query.level ? this.$route.query.level : null;
      if(level_id) {
        this.getMajorList({params: {level: level_id}})
          .then(response => {
            var res = response;
            this.majorOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.majorOptionList.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.majorOptionList = [{value: null, text: "All"}];
      }
    },
    classList() {
      var level_id = this.$route.query.level ? this.$route.query.level : null;
      var major_id = this.$route.query.major ? this.$route.query.major : null;
      if(level_id || major_id) {
        this.getClassList({params: {level: level_id, major: major_id}})
          .then(response => {
            var res = response;
            this.classOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var dataclass = {
                value: res[i].id,
                text: res[i].name
              };
              this.classOptionList.push(dataclass);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.classOptionList = [{value: null, text: "All"}];
      }
    },
    subjectList() {
      var level_id = this.$route.query.level ? this.$route.query.level : null;
      var major_id = this.$route.query.major ? this.$route.query.major : null;
      var class_id = this.$route.query.class ? this.$route.query.class : null;
      if(level_id || major_id || class_id) {
        this.getSubjectList({params: {level: level_id, major: major_id, class: class_id}})
          .then(response => {
            var res = response;
            this.subjectOptionList = [{value: null, text: "All"}];
            for (var i = 0; i < res.length; i++) {
              var datasubject = {
                value: res[i].id,
                text: res[i].name
              };
              this.subjectOptionList.push(datasubject);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.subjectOptionList = [{value: null, text: "All"}];
      }
    },
    chapterList() {
      this.level_id = this.$route.query.level ? this.$route.query.level : null;
      this.major_id = this.$route.query.major ? this.$route.query.major : null;
      this.class_id = this.$route.query.class ? this.$route.query.class : null;
      this.subject_id = this.$route.query.subject ? this.$route.query.subject : null;
      this.curriculum_id = this.$route.query.curriculum ? this.$route.query.curriculum : null;
      this.isBusy = true;
      this.getChapterList({params: {level: this.level_id, major: this.major_id, class: this.class_id, subject: this.subject_id, curriculum: this.curriculum_id}})
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              name: res[i].name,
              level: res[i].level,
              level_id: res[i].level_id,
              major: res[i].major,
              major_id: res[i].major_id,
              class: res[i].class,
              class_id: res[i].class_id,
              subject: res[i].subject,
              subject_id: res[i].subject_id,
              curriculum: res[i].curriculum,
              curriculum_id: res[i].curriculum_id,
              description: res[i].description,
              class_major: res[i].major ? res[i].class+"-"+res[i].major : res[i].class
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleEdit(no) {
      this.editData = true;
      this.addModal = true;
      this.getChapterDetail(no)
        .then(response => {
          var res = response;
          this.inputData.name = res.name;
          this.inputData.description = res.description;
          this.inputData.id = res.id;
          this.inputData.level_id = res.level_id;
          this.changeInputLevel(res.major_id);
          this.inputData.major_id = res.major_id;
          this.changeInputMajor(res.class_id);
          this.inputData.class_id = res.class_id;
          this.changeInputClass(res.subject_id);
          this.inputData.subject_id = res.subject_id;
          this.inputData.curriculum_id = res.curriculum_id;
          this.inputData.thumbnail_src = res.thumbnail ? res.thumbnail : '/img/thumbnail.png';
          this.inputData.thumbnail = null;
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    handleSave() {
      if(this.majorOption == 0) {
        if(this.inputData.level_id && this.inputData.class_id && this.inputData.subject_id && this.inputData.curriculum_id && this.inputData.name) {
          if(this.editData) {
            this.editChapter();
          } else {
            this.addChapter();
          }
        } else {
          this.showMessageBox("Data belum lengkap", "Failed", "success");
        }
      } else {
        if(this.inputData.level_id && this.inputData.major_id && this.inputData.class_id && this.inputData.subject_id && this.inputData.curriculum_id && this.inputData.name) {
          if(this.editData) {
            this.editChapter();
          } else {
            this.addChapter();
          }
        } else {
          this.showMessageBox("Data belum lengkap", "Failed", "success");
        }
      }
    },
    handleGo(item) {
      this.$router.push("/master-data/video?chapter="+item.id);
    },
    handleDelete(index) {
      this.$bvModal.msgBoxConfirm('Delete this data?', {
        title: 'Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(value) {
            this.deleteChapter({id:index.id})
              .then(response => {
                this.chapterList();
                this.showMessageBox("Deleted", "Success", "success");
              })
              .catch(error => {
                this.showMessageBox(error, "Failed", "success");
              });
          }
        })
        .catch(err => {
          this.showMessageBox(err, "Failed", "success");
        });
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm");
    },
    addChapter() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputData.name);
      newFormData.append("description", this.inputData.description);
      newFormData.append("subject_id", this.inputData.subject_id);
      newFormData.append("curriculum_id", this.inputData.curriculum_id);
      if(this.inputData.thumbnail) {
        newFormData.append("thumbnail", this.inputData.thumbnail);
      }
      this.setNewChapter(newFormData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.addModal = false;
          this.showLoading = false;
          this.inputData = {
            name: "",
            description: "",
            level_id: null,
            major_id: null,
            class_id: null,
            subject_id: null
          };
          this.chapterList();
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    editChapter() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("name", this.inputData.name);
      newFormData.append("description", this.inputData.description);
      newFormData.append("curriculum_id", this.inputData.curriculum_id);
      newFormData.append("subject_id", this.inputData.subject_id);
      newFormData.append("id", this.inputData.id);
      if(this.inputData.thumbnail) {
        newFormData.append("thumbnail", this.inputData.thumbnail);
      }
      if(this.inputData.removeOld) {
        newFormData.append("removeOld", this.inputData.removeOld);
      }
      this.updateChapter(newFormData)
        .then(response => {
          // eslint-disable-next-line
          var res = response;
          this.addModal = false;
          this.showLoading = false;
          this.chapterList();
        })
        .catch(error => {
          this.showLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>